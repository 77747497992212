var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "video_sequence" } }, [
    _c(
      "video",
      {
        class: _vm.isOutro ? "outro" : "intro",
        attrs: { id: "game_video", autoplay: "" },
        domProps: { muted: _vm.muted },
      },
      [
        _c("source", {
          attrs: { src: _vm.video.videoPathMp4, type: "video/mp4" },
        }),
        _c("source", {
          attrs: { src: _vm.video.videoPathOgg, type: "video/ogg" },
        }),
        _c("p", [_vm._v(_vm._s(_vm.$t("html5_video_not_supported")))]),
      ]
    ),
    _c("div", { staticClass: "narration_actions video_actions" }, [
      _c("a", {
        staticClass: "right skipNarration img-btn img-hover-btn",
        attrs: { id: "skipVideo" },
        on: { click: _vm.endVideo },
      }),
    ]),
    _c("div", {
      staticClass: "video-bar",
      style: { width: _vm.progress + "%" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }