<template>
  <div id="video_sequence">
    <video
      id="game_video"
      :muted="muted"
      :class="isOutro ? 'outro' : 'intro'"
      autoplay
    >
      <source
        :src="video.videoPathMp4"
        type="video/mp4"
      >
      <source
        :src="video.videoPathOgg"
        type="video/ogg"
      >
      <p>{{ $t("html5_video_not_supported") }}</p>
    </video>
    <div class="narration_actions video_actions">
      <a
        id="skipVideo"
        class="right skipNarration img-btn img-hover-btn"
        @click="endVideo"
      />
    </div>
    <div
      class="video-bar"
      :style="{width: progress + '%'}"
    />
  </div>
</template>

<script>
export default {
  name: 'VideoSequence',
  data() {
    return {
      progress: 0
    }
  },
  computed: {
    isOutro() {
      return this.$parent.isGameEnd
    },

    muted() {
      return this.$store.getters.mutedBgSound
    },

    video() {
      return this.isOutro ? this.$store.getters.gameplay.outroVideoSequence : this.$store.getters.gameplay.introVideoSequence
    }
  },
  mounted() {
    const video = document.getElementById('game_video')

    video.onended = () => {
      this.endVideo()
    }
    video.ontimeupdate = () => {
      this.progress = Math.floor((100 / video.duration) * video.currentTime)
    }
  },
  methods: {
    endVideo() {
      this.$parent.routeGame()
    }
  }
}
</script>

<style lang="scss" scoped>
  .video_actions {
    position: fixed;
    bottom: 8%;
    padding: 0;
    right: 4%;
    left: auto;
    width: 20%;
    height: 5.3%;
  }

  .video-bar {
    position: absolute;
    top: 0;
    width: 0%;
    height: 7px;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    -moz-transition: width 1.5s;
    -webkit-transition: width 1.5s;
    -o-transition: width 1.5s;
    transition: width 1.5s;
  }

  .video-bar:hover {
    height: 10px;
  }
</style>
